import React, { useContext, useEffect, useState } from 'react';
import './Profile.css';
import { useFormAndValidation } from '../../hooks/useFormAndValidation';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { validateEmail, validateName } from '../../utils/validation';

const Profile = ({ onSignOut, onUpdateUser, apiErrors, isOK }) => {
  const { values, handleChange, isValid, setValues, setIsValid } = useFormAndValidation();
  const { currentUser } = useContext(CurrentUserContext);

  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  useEffect(() => {
    if (currentUser) {
      setValues(currentUser);
      setIsValid(true);
    }
  }, [currentUser, setIsValid, setValues]);

  useEffect(() => {
    if (isOK) {
      setShowSaveBtn(false);
      setShowSuccessMsg(true);
    }
  }, [isOK, apiErrors]);

  const onSubmit = (e) => {
    e.preventDefault();
    onUpdateUser(values);
  };

  return (
    <section className="profile">
      <h1 className="profile__welcome-message">Привет, {currentUser.name}!</h1>
      <form
        onSubmit={onSubmit}
        className="profile-form"
      >
        <div className="profile-form__input-field">
          <label className="profile-form__label" htmlFor="user-name-input">
            Имя
          </label>
          <input
            className="profile-form__input"
            id="user-name-input"
            name="name"
            value={values.name || ''}
            onChange={handleChange}
            type="text"
            placeholder="Введите имя"
            minLength="2"
            maxLength="40"
            required
            disabled={!showSaveBtn}
          />
          <span className={`profile-form__input-error`}>
            {validateName(values.name).message}
          </span>
        </div>

        <div className="profile-form__input-field">
          <label className="profile-form__label" htmlFor="user-email-input">
            E-mail
          </label>
          <input
            className="profile-form__input"
            id="user-email-input"
            name="email"
            value={values.email || ''}
            onChange={handleChange}
            type="email"
            placeholder="Введите почту"
            minLength="2"
            maxLength="40"
            required
            disabled={!showSaveBtn}
          />
          <span
            className={
              'profile-form__input-error profile-form__input-error_active'
            }
          >
            {validateEmail(values.email).message}
          </span>
        </div>

        <div className="profile-form__buttons">
          {apiErrors.profile && (
            <span className="profile-form__error-message">
              {apiErrors.profile.errorText}
            </span>
          )}

          {showSuccessMsg && (
            <span className="profile-form__success-message">
              Данные успешно обновлены!
            </span>
          )}

          {showSaveBtn ? (
            <button
              type="submit"
              className="profile-form__button profile-form__button-save"
              disabled={
                !isValid ||
                (values.name === currentUser.name &&
                  values.email === currentUser.email) ||
                validateEmail(values.email).invalid ||
                validateName(values.name).invalid
              }
            >
              Сохранить
            </button>
          ) : (
            <button
              type="button"
              className="profile-form__button profile-form__button-edit"
              onClick={(e) => {
                e.preventDefault();
                setShowSaveBtn(true);
                setShowSuccessMsg(false);
              }}
            >
              Редактировать
            </button>
          )}

          <button
            onClick={onSignOut}
            className="profile-form__button profile-form__button-signout"
          >
            Выйти из аккаунта
          </button>
          </div>
      </form>
    </section>
  );
};

export default Profile;
